import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

export const CasiCliniciTemplate = ({ title, content, video, casi, contentComponent }) => {
  const PageContent = contentComponent || Content
  const [currentOver, setCurrentOver] = useState(-1);
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <PageContent className="content" content={content} />
              <div className="casi-clinici">
                <div className="casi-clinici-prima">
                  <h4>Prima</h4>
                  {casi.map((c, idx) =>
                    <div
                      onMouseOver={() => setCurrentOver(idx)}
                      onMouseOut={() => setCurrentOver(-1)}
                      className={currentOver === idx ? 'is--over': ''}
                    >
                      <PreviewCompatibleImage imageInfo={c.prima} />
                    </div>
                  )}
                </div>
                <div className="casi-clinici-dopo">
                  <h4>Dopo</h4>
                  {casi.map((c, idx) =>
                    <div
                      onMouseOver={() => setCurrentOver(idx)}
                      onMouseOut={() => setCurrentOver(-1)}
                      className={currentOver === idx ? 'is--over': ''}
                    >
                      <PreviewCompatibleImage imageInfo={c.dopo} />
                    </div>
                  )}
                </div>
              </div>
              <div className="casi-clinici-video">
                {video.map(v => 
                  <div key={v.id} className="embed-container">
                    <iframe title={`video-${v.id}`} src={`https://www.youtube.com/embed/${v.id}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

CasiCliniciTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  video: PropTypes.array,
  casi: PropTypes.array,
}

const CasiCliniciPage = ({ data }) => {
  const { markdownRemark: page } = data
  return (
    <Layout>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${page.frontmatter.immagine.childImageSharp.fluid.src})`,
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 main-title"
          style={{
            color: '#2fbaff',
            padding: '1rem',
            backgroundColor: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          {page.frontmatter.titolo}
        </h1>
      </div>
      <CasiCliniciTemplate
        contentComponent={HTMLContent}
        casi={page.frontmatter.casi}
        video={page.frontmatter.video}
        content={page.html}
      />
    </Layout>
  )
}

CasiCliniciPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default CasiCliniciPage

export const casiCliniciPageQuery = graphql`
  query ProfiloPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        titolo
        casi {
          prima {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          dopo {
            childImageSharp {
              fluid(maxWidth: 240, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        video {
          id
        }
        immagine {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
